/* eslint-disable no-restricted-imports */
import { initGraphQLTada } from "gql.tada";
import type { introspection } from "generated/graphql-env";
import { OperationVariables, TypedDocumentNode } from "@apollo/client";
import { useState } from "react";
import { LocalStorage } from "shared/utils/local-storage";
import { apiHost } from "shared/api/api-host";
import { print as gqlDocToQueryString } from "graphql";

export const graphql = initGraphQLTada<{
  introspection: introspection;
  scalars: {
    BigDecimal: string;
    ISO8601Date: string;
    ISO8601DateTime: string;
    JSON: unknown;
  };
  tadaTurboLocation: "shared/generated/";
}>();

export type {
  FragmentOf,
  ResultOf,
  VariablesOf,
  TadaDocumentNode,
} from "gql.tada";
export { readFragment as unmaskFragment } from "gql.tada";
export type ID = string | number;

type CachelessData<TData> = {
  data: TData | undefined;
  error: Error | undefined;
  loading: boolean;
};

export function useQueryWithoutCaching<
  TVariables extends OperationVariables,
  TData
>(
  queryDocumentNode: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables
): CachelessData<TData> {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<TData | undefined>();
  const [error, setError] = useState<Error | undefined>();

  LocalStorage.getItem("accessToken")
    .then((authToken) =>
      fetch(`${apiHost}/graphql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken != null ? `Bearer ${authToken}` : "",
        },
        body: JSON.stringify({
          query: gqlDocToQueryString(queryDocumentNode),
          variables,
        }),
      })
    )
    .then((response) => response.json())
    .then((data) => {
      setData(data.data);
    })
    .catch((error: Error) => {
      setError(error);
    })
    .finally(() => setLoading(false));
  return { data, error, loading };
}

async function postToGraphQL<TVariables extends OperationVariables, TData>(
  method: "query" | "mutation",
  queryDocumentNode: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables
): Promise<TData | undefined> {
  const authToken = await LocalStorage.getItem("accessToken");

  const response = await fetch(`${apiHost}/graphql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken != null ? `Bearer ${authToken}` : "",
    },
    body: JSON.stringify({
      [method]: gqlDocToQueryString(queryDocumentNode),
      variables,
    }),
  });

  const result = await response.json();
  return result.data;
}

export async function fetchQueryWithoutCaching<
  TVariables extends OperationVariables,
  TData
>(
  queryDocumentNode: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables
): Promise<TData | undefined> {
  return postToGraphQL("query", queryDocumentNode, variables);
}

export async function postMutationWithoutCaching<
  TVariables extends OperationVariables,
  TData
>(
  mutationDocumentNode: TypedDocumentNode<TData, TVariables>,
  variables?: TVariables
): Promise<TData | undefined> {
  return postToGraphQL("mutation", mutationDocumentNode, variables);
}
